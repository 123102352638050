import React from 'react';
import * as S from './styled';

const RichText = ({ ...props }) => {

  return (
    <S.RichText {...props} />
  );
};

export default RichText;
