var unified = require("unified");
var markdown = require("remark-parse");
var html = require("remark-html");

export function ConvertMarkdownToHtml(content) {
  let parsedContent = content;
  unified()
    .use(markdown)
    .use(html)
    .process(content, function(err, file) {
      if (err) {
        console.error('md to html error: ', err)
      } else {
        parsedContent = String(file);
      }
    });
  return parsedContent;
}
